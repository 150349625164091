import React from 'react'

import styles from './Loading.cssm'

const Loading = () => (
  <div>
    <div className={styles.square} />
    <div className={styles.square} />
    <div className={styles.square} />
  </div>
)

export default Loading
